

@font-face {
	font-family: 'Champions';
	font-style: normal;
	font-weight: 300;
	src: local('Champions'), url('./fonts/Champions-Bold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'Europa-Big';
	font-style: normal;
	font-weight: 300;
	src: local('Europa-Big'), url('./fonts/europatitlebold.ttf') format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'Europa-Regular';
	font-style: normal;
	font-weight: 300;
	src: local('Europa-Regular'), url('./fonts/EuropaNuova-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}


body {
 	margin: 0;
  	font-family: 'Europa-Regular';
 	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scrollbar-color:#F89504 black;

}

code {
  font-family: 'Europa-Regular';
}

#root {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@-webkit-keyframes fade {
	from {opacity: .4} 
	to {opacity: 1}
}
  
@keyframes fade {
	from {opacity: .4} 	
	to {opacity: 1}
}

/* width */
::-webkit-scrollbar {
	width: 12px;
}
  
/* Track */
::-webkit-scrollbar-track {
	box-shadow: inset 1px 0 1px #F89504; 
	border-radius: 2px;
	cursor: all-scroll;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
	background: #F89504;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #F89504; 
}

#mapId .leaflet-marker-icon {
	max-width: 60px !important;
	max-height: 40px !important;
	background-color: transparent !important;
	border: none !important;
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
